var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('input',{attrs:{"type":"hidden","name":"_token"},domProps:{"value":_vm.csrf}}),_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.newItemLabel))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('Input',{attrs:{"label":"اسم المنطقة","type":"text","model":_vm.form.name,"errorMessages":_vm.nameErrors},on:{"changeValue":function (val) {
                  _vm.form.name = val;
                  _vm.$v.form.name.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('Input',{attrs:{"label":"latitude","type":"text","placeholder":'33,3...',"model":_vm.form.latitude,"errorMessages":_vm.latitudeErrors},on:{"changeValue":function (val) {
                  _vm.form.latitude = val;
                  _vm.$v.form.latitude.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('Input',{attrs:{"label":"longitude","placeholder":'36,3...',"type":"text","model":_vm.form.longitude,"errorMessages":_vm.longitudeErrors},on:{"changeValue":function (val) {
                  _vm.form.longitude = val;
                  _vm.$v.form.longitude.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('FormSelect',{attrs:{"items":_vm.Locations,"model":_vm.form.parent_id,"attr":"parent_id","errorMessages":_vm.parent_idErrors,"label":"ضمن المحافظة"},on:{"select":function (val) {
                  _vm.form.parent_id = val.value;
                  _vm.$v.form.parent_id.$touch();
                }}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('Button',{attrs:{"color":"blue darken-1","type":"submit","label":"حفظ"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }