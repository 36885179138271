import { render, staticRenderFns } from "./ReasonReject.vue?vue&type=template&id=51e8b6cc&"
import script from "./ReasonReject.vue?vue&type=script&lang=js&"
export * from "./ReasonReject.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../AppData/Roaming/npm/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VCombobox,VContainer,VRow})
